const level1 = {
    name: "Circuit 1",
    width: 40,
    height: 40,
    rows: [
      "                BBBBB                   ",
      "                B   B                   ",
      "                B S B                   ",
      "                B   B BBBBBBBBBBBBB     ",
      "                B   B B           B     ",
      "                B   BBB     BBB   B     ",
      " m              B          B   B  B     ",
      "                B          B M B  B     ",
      "                BBBBBBBBBBBB   B  B     ",
      "                               B  B     ",
      "                               B  B     ",
      "       T                       B  B     ",
      "                      O        B  B     ",
      "                               B  B     ",
      "                BBBBBBBBBBBBBBBB  B     ",
      "                B                 B     ",
      "        BBBBBBBBB                 B     ",
      "        B                         B     ",
      "        B    BBBBBBBBBBBBBBBBBBBBBB     ",
      "        B    B                          ",
      "        B    BBBBB                      ",
      "        BBB      BB            V        ",
      "         BBBBBBB   BB                   ",
      "               BB   BB                  ",
      "                B     B                 ",
      "                 BBB   B                ",
      "                   BB   B               ",
      "                    BB  BB              ",
      "                     B   B              ",
      "                     BB   B             ",
      "      m              BB   B             ",
      "                     BbbFbbB            ",
      "                  BBBEEEEEEB            ",
      "                  BBBBBBBBBB            ",
      "                                        ",
      "                                        ",
      "                                        ",
      "                                        ",
      "                                        ",
      "                                        ",
    ],
  };

const level2 = {
    name: "Circuit 2",
    width: 40,
    height: 40,
    rows: [
      "                                        ",
      "                                        ",
      "                                        ",
      "                                        ",
      "                  M                     ",
      "                                        ",
      "                                        ",
      "                                        ",
      "                                        ",
      "                                        ",
      "                                        ",
      "                                        ",
      "                                        ",
      "           BBBBBBB                      ",
      "          B       BB                    ",
      "         B  BBBBB   B                   ",
      "       BB  B     B   BBB                ",
      "      B   B  F    B    B                ",
      "  BBBB   B  BBBB   B   B                ",
      "  B     B  B    B   B  B                ",
      "  B    B  B    B       B                ",
      "  B    B B     BBBBBBBB                 ",
      "  B S  B B                              ",
      "  B    B  B   V                         ",
      "  B   BB  B                             ",
      "  B   B  BB                             ",
      "  B      B                              ",
      "  BBBBBBBB                              ",
      "                                        ",
      "     O                                  ",
      "                                        ",
      "                                        ",
      "                                        ",
      "                                        ",
      "                       m                ",
      "                                        ",
      "                                        ",
      "                                        ",
      "                                        ",
      "                                        ",
    ],
}



export const levels = [
    level1,
    level2
]